import OpportunitesList from "./opportunities/opportunitiesList";
import AdminAppBar from "./adminAppBar";

export type AdminProps = {
  username: string
}

const App = (props: AdminProps) => {
  return (
    <>
      <AdminAppBar username={props.username} />
      <OpportunitesList />
    </>
  )
};

export default App;