const clientId = '9518526c-e5a0-42b9-9b42-a5f454886f5f'
const tenant = 'volonteros.onmicrosoft.com'
const instance = 'volonteros.b2clogin.com'
const policySignIn = 'B2C_1_si'
const authoritySignIn = `https://${instance}/${tenant}/${policySignIn}`
const scopeUserProfile = `https://${tenant}/api/Volonteros.UserProfile`
const adminUri = process.env.REACT_APP_BASE_ADMIN_URL

export const b2cPolicies = {
    names: {
        signIn: policySignIn,
    },
    authorities: {
        signIn: {
            authority: authoritySignIn,
        },
    },
    authorityDomain: instance,
};

export const msalConfig = {
    auth: {
        clientId: clientId,
        authority: b2cPolicies.authorities.signIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: adminUri,
        postLogoutRedirectUri: adminUri,
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
    },
};

export const loginRequest = {
    scopes: [scopeUserProfile],
};