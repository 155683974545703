
import { useMsal } from '@azure/msal-react';
import { ThemeProvider, useTheme } from '@emotion/react';
import { Container, CssBaseline, Box, Typography, Button } from '@mui/material';

const UnauthorizedPage = () => {
    const theme = useTheme();
    const { instance } = useMsal();

    const handleSubmit = () => {
        instance.logoutRedirect().catch((e) => {
            console.error(e);
        });
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '100vh',
                        flexDirection: 'column',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Unauthorized
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign Out
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default UnauthorizedPage;
