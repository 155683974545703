import { Checkbox } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';

export type OpportunitySelectFeaturedProps = {
    id: number,
    isFeatured: boolean,
  }

const OpportunitySelectFeatured = (props: OpportunitySelectFeaturedProps) => {
    const [isFeatured, setIsFeatured] = useState(props.isFeatured)

    const toggleIsFeatured = () => {
        if (isFeatured) {
            axios.put(`opportunities/${props.id}/featured/unset`).then(response => {
                setIsFeatured(false)
            })
        } else {
            axios.put(`opportunities/${props.id}/featured/set`).then(response => {
                setIsFeatured(true)
            })
        }
        
    }

    return (
        <>
            <Checkbox checked={isFeatured} onChange={toggleIsFeatured} />
        </>
    );
}

export default OpportunitySelectFeatured;