import axios from "axios";
import { useState } from "react";
import LoadingPage from "./loadingPage";
import Admin from "./index";
import UnauthorizedPage from "./unauthorizedPage";

const AuthorizedPages = () => {
    const [loading, setLoading] = useState(false);
    const [authorized, setAuthorized] = useState<boolean | undefined>();
    const [username, setUsername] = useState("");

    if (!loading && !username && authorized === undefined) {
        setLoading(true);
        axios.get("profile").then(response => {
            if (response.status === 200) {
                setUsername(response.data);
                setAuthorized(true);
            }
            setLoading(false);
        }).catch(() => {
            setAuthorized(false);
            setLoading(false);
        })
    }

    return (
        <>
            {loading &&
                <LoadingPage />
            }
            {!loading && authorized &&
                <Admin username={username} />
            }
            {!loading && !authorized &&
                <UnauthorizedPage />
            }
        </>
    );
}

export default AuthorizedPages;