import { Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import LoadingPage from "../loadingPage";
import OpportunitySelectFeatured from "./opportunitySelectFeatured";

type Opportunity = {
    id: number,
    title: string,
    status: string,
    isFeatured: boolean,
}

const OpportunitesList = () => {
    const [loading, setLoading] = useState(false);
    const [opportunities, setOpportunities] = useState<Opportunity[]>();

    if (!loading && !opportunities) {
        setLoading(true);
        axios.get("opportunities").then(response => {
            if (response.status === 200) {
                setOpportunities(response.data.opportunities);
            }
            setLoading(false);
        })
    }

    return (
        <>
            {loading &&
                <LoadingPage />
            }
            {!loading &&
                <>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Featured</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {opportunities && opportunities.map((opportunity) => (
                                <TableRow
                                    hover
                                    key={opportunity.id}>
                                    <TableCell>{opportunity.id}</TableCell>
                                    <TableCell>{opportunity.title}</TableCell>
                                    <TableCell>{opportunity.status}</TableCell>
                                    <TableCell><OpportunitySelectFeatured id={opportunity.id} isFeatured={opportunity.isFeatured} /></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </>
            }
        </>
    );
}

export default OpportunitesList;