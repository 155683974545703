
import { useTheme, ThemeProvider } from '@emotion/react';
import { Container, CssBaseline, CircularProgress, Box } from '@mui/material';

const LoadingPage = () => {
    const theme = useTheme();
    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '100vh',
                    }}
                >
                    <CircularProgress />
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default LoadingPage;