import * as React from 'react';
import { useMsal } from '@azure/msal-react';
import { Box, AppBar, Toolbar, Typography, Button, Menu, MenuItem } from '@mui/material';

export type AdminAppBarProps = {
    username: string
  }

const AdminAppBar = (props: AdminAppBarProps) => {
    const { instance } = useMsal();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  
    const handleSignOut = () => {
        instance.logoutRedirect().catch((e) => {
            console.error(e);
        });
    };
  
    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Opportunities
          </Typography>
          <Button
                color="inherit"
                onClick={handleMenu}
                >
                {props.username}
        </Button>
          <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
              </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default AdminAppBar;