import { InteractionStatus } from "@azure/msal-browser";
import { useAccount, useMsal } from "@azure/msal-react";
import axios from "axios";
import { useState } from "react";
import { loginRequest } from "../authConfig";
import LoadingPage from "./loadingPage";
import LoginPage from "./loginPage";

interface RequestInterceptorProps {
    children: JSX.Element,
}

const RequestInterceptor: React.FC<RequestInterceptorProps> = ({ children }: RequestInterceptorProps) => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0]);
    const [authenticated, setAuthenticated] = useState(false);

    if (account && !authenticated) {
        setAuthenticated(true);
    }

    axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL
    axios.interceptors.request.use(async (config) => {
        if (account) {
            const msalResponse = await instance.acquireTokenSilent({
                ...loginRequest,
                account: account,
            });

            const bearer = `Bearer ${msalResponse.accessToken}`;
            if (config?.headers) {
                (config.headers as any)["Authorization"] = bearer;
            }
        }

        return config;
    });

    return (
        <>
            {inProgress !== InteractionStatus.None &&
                <LoadingPage />
            }
            {inProgress === InteractionStatus.None && authenticated && children}
            {inProgress === InteractionStatus.None && !authenticated &&
                <LoginPage />
            }
        </>
    );
};

export default RequestInterceptor;