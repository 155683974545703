import { IPublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { FC } from "react";
import AuthorizedPages from "./admin/authorizedPages";
import RequestInterceptor from "./admin/requrestInterceptor";

export type AppProps = {
    msalInstance?: IPublicClientApplication | undefined
}

const App: FC<AppProps> = ({ msalInstance }) => {
    return (
        <>
            {msalInstance &&
                <MsalProvider instance={msalInstance} >
                    <RequestInterceptor>
                        <AuthorizedPages />
                    </RequestInterceptor>
                </MsalProvider>
            }
        </>
    )
};

export default App;
